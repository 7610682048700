import { cx } from '@emotion/css'
import {
    AppState,
    AuthenticationState,
    capitalize,
    ScriptLoadResult,
    useFeature,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { TaboolaProps } from './Taboola'
import { StyledTaboolaContainer } from './Taboola.styled'
import { TaboolaContext } from './TaboolaScriptProvider'
import debug from 'debug'
import { useLocation } from 'react-router'

export const Taboola: React.FC<TaboolaProps> = ({
    locationType,
    taboolaContainerId,
    isFourColumns,
    disableBreachAds
}) => {
    const location = useLocation()

    const taboolaContext = useContext(TaboolaContext)
    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )

    const taboolaMode =
        renditionType === 'app'
            ? 'alternating-thumbnails-a-app'
            : 'alternating-thumbnails-a'

    const placementType =
        `Below ${capitalize(locationType)} Thumbnails` +
        (isFourColumns ? ' Wide' : '')

    const isMidArticleThumbnailEnabled = useFeature('7news-taboola-midarticle')

    React.useEffect(() => {
        const { result, newPage, flushQueue, addPlacement } =
            taboolaContext ?? {}

        const pageId = `${location.pathname}${location.search}${location.hash}`

        const logger = debug('_taboola:web')

        if (result === ScriptLoadResult.Success) {
            // pass the page details to taboola; see https://developers.taboola.com/web-integrations/docs/spa#pass-the-page-details
            newPage?.(pageId, {
                [locationType]: 'auto',
                url: window.location.href,
            })

            // pass the placement details to taboola; see https://developers.taboola.com/web-integrations/docs/spa#pass-the-placement-details
            addPlacement?.(pageId, {
                mode: taboolaMode,
                container: taboolaContainerId,
                placement: placementType,
                target_type: 'mix',
            })

            if (isMidArticleThumbnailEnabled) {
                addPlacement?.(pageId, {
                    mode: 'thumbnails-a',
                    container: 'taboola-mid-article-thumbnails',
                    placement: 'Mid Article Thumbnails',
                    target_type: 'mix',
                })
            }

            // flush the command queue; see https://developers.taboola.com/web-integrations/docs/spa#flush-the-queue
            flushQueue?.(pageId)
        }
    }, [
        location.pathname,
        location.search,
        location.hash,
        taboolaContainerId,
        locationType,
        taboolaMode,
        placementType,
        taboolaContext,
        isMidArticleThumbnailEnabled,
    ])

    /** Breachwall Checks */
    /** Disable/Hide Ad content for breachwall pages */
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const isEntitled = authentication.isEntitled
    if(disableBreachAds && !isEntitled) return null

    return (
        <StyledTaboolaContainer
            id={taboolaContainerId}
            className={cx('hide-print')}
        ></StyledTaboolaContainer>
    )
}
Taboola.displayName = 'Taboola'

export default Taboola
